// @refresh reload
import './app.css';
import { Router } from '@solidjs/router';
import { FileRoutes } from '@solidjs/start/router';
import { Suspense } from 'solid-js';
import { ImagePreview } from './components/common/ImagePreview';
import { Toaster } from './components/common/toaster/Toaster';
import Navigator from './framework/Navigator';
import { CompanyStyling } from './global-contexts/CompanyStyling';
import { ErrorHandler } from './global-contexts/ErrorHandler';

export default function Root() {
  return (
    <Router
      root={(props) => (
        <Suspense>
          <CompanyStyling>
            <ErrorHandler>
              <Navigator />
              {props.children}
              <ImagePreview />
              <Toaster />
            </ErrorHandler>
          </CompanyStyling>
        </Suspense>
      )}>
      <FileRoutes />
    </Router>
  );
}
