import { type Component, createSignal, Show } from 'solid-js';
import { useLockBodyScroll } from '~/hooks/useLockBodyScroll';
import { IconLoader, IconX } from './icons';

const [imageUrl, setImageUrl] = createSignal<string>();

export const preview = (imageUrl: string) => {
  setImageUrl(imageUrl);
};

export const ImagePreview: Component = () => {
  useLockBodyScroll(() => imageUrl() != null);

  return (
    <Show when={imageUrl()}>
      <div class="fixed inset-0 z-50 flex items-center justify-center p-10">
        <div class="absolute inset-0 -z-10 bg-black/80" onClick={() => setImageUrl(undefined)} />
        <button
          class="absolute right-4 top-3 rounded-full border border-white p-2 text-white hover:bg-white/20"
          onClick={() => setImageUrl(undefined)}>
          <IconX aria-label="Close" />
        </button>
        <IconLoader class="absolute -z-10 size-10 animate-spin text-white" />
        <img class="max-h-full max-w-full bg-faded" src={imageUrl()} />
      </div>
    </Show>
  );
};
