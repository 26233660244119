import { useNavigate } from '@solidjs/router';
import { onCleanup, onMount } from 'solid-js';
import { eventEmitter } from './eventEmitter';

const Navigator = () => {
  const navigate = useNavigate();

  const handleNavigation = (url: string) => {
    if (url) {
      navigate(url);
    }
  };

  onMount(() => {
    eventEmitter.registerForNavigation(handleNavigation);
  });

  onCleanup(() => {
    eventEmitter.unregisterFromNavigation(handleNavigation);
  });
};

export default Navigator;
