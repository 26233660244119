import { Component, JSX, createContext, useContext, createEffect } from 'solid-js';
import usePresenter from '~/framework/hooks/usePresenter';
import { CompanyStylePresenter, PresentableCompayStyle } from '~/presenters/CompanyStylePresenter';

interface CompanyStylingType {
  companyStyling?: PresentableCompayStyle;
}

const CompanyStylingContext = createContext<CompanyStylingType>();

interface CompanyStylingProps {
  children: JSX.Element;
}

export const CompanyStyling: Component<CompanyStylingProps> = (props) => {
  const { model: companyStyling } = usePresenter(CompanyStylePresenter);

  createEffect(() => {
    if (companyStyling()?.primaryColor) {
      document.documentElement.style.setProperty('--primary-color', companyStyling()?.primaryColor || null);
    }
    if (companyStyling()?.hoverColor) {
      document.documentElement.style.setProperty('--hover-color', companyStyling()?.hoverColor || null);
    }
    if (companyStyling()?.activeColor) {
      document.documentElement.style.setProperty('--active-color', companyStyling()?.activeColor || null);
    }
    if (companyStyling()?.gradientStartColor) {
      document.documentElement.style.setProperty('--gradient-color-start', companyStyling()?.gradientStartColor || null);
    }
    if (companyStyling()?.gradientEndColor) {
      document.documentElement.style.setProperty('--gradient-color-end', companyStyling()?.gradientEndColor || null);
    }
  });
  return <CompanyStylingContext.Provider value={{ companyStyling }}>{props.children}</CompanyStylingContext.Provider>;
};

export function useStyling() {
  const context = useContext(CompanyStylingContext);
  if (!context) {
    throw new Error('useStyling must be used within an CompanyStylingContext');
  }
  return context;
}
