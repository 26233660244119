import * as Sentry from '@sentry/browser';
import { mount, StartClient } from '@solidjs/start/client';
import { DEV } from 'solid-js';

if (!DEV) {
  Sentry.init({
    dsn: 'https://ab83539513deb75f649265b10614171c@sentry.magicdoor.io/3',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

mount(() => <StartClient />, document.getElementById('app') as HTMLDivElement);
