import { Presenter } from '~/framework/Presenter';
import { User } from '~/state/mainAppState';

export interface PresentableCompayStyle {
  name: string;
  logo?: string;
  primaryColor?: string;
  hoverColor?: string;
  activeColor?: string;
  gradientStartColor?: string;
  gradientEndColor?: string;
}

export class CompanyStylePresenter extends Presenter<PresentableCompayStyle> {
  protected createModel = (state: User): PresentableCompayStyle => {
    return {
      name: state.companyInfo?.name || '',
      logo: state.companyInfo?.logo,
      primaryColor: state.companyInfo?.style.primaryColor,
      hoverColor: state.companyInfo?.style.hoverColor,
      activeColor: state.companyInfo?.style.activeColor,
      gradientStartColor: state.companyInfo?.style.gradientStartColor,
      gradientEndColor: state.companyInfo?.style.gradientEndColor,
    };
  };
}
